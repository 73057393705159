import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, {MTableToolbar} from 'material-table';
import jwtDecode from "jwt-decode";
import {SENDEMAIL} from "../config.js";
import axios from "axios";
import UserAPI from '../services/UserApi';

import { Grid, 
         Typography,
         Paper,
         Tabs,
         Tab,
         FormControl,
         FormHelperText,
         Select,
         MenuItem,
        } from '@material-ui/core';

//icons
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FcOk } from "react-icons/fc";
        
//error message
import ErrorMessage from '../components/errorMessage'
//password generator
import passwordGenerator from '../services/passwordGenerator'
//styling
import * as styling from '../services/styling'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '15vh',
        marginLeft: '30px',
        width: 'fit-content',
        //remplace with breakpoint
    },
    table: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    title: {
        backgroundColor: '#1c60A3',
        color: '#FFF',
        height: "5vh",
        textTransform: "uppercase",
    },
    titleText : {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop:'25px',
        color : styling.colorTextTitle,
        textTransform: "uppercase"
    },
    toolbar:{
        backgroundColor: styling.backGroundHeader,
        
    },
    page: {
        marginLeft: '30px',
        marginRight: '30px',
    },
}));
const Administration = () => {
    const classes = useStyles();

    const [roleAdmin, setRoleAdmin] = useState(false);

    const [users, setUsers] = useState([]);
    const [refreshTab, setRefreshTab] = useState(false);
    
    useEffect(() => {
        const getDecodedToken = () => {
            const tokenBrut = window.localStorage.getItem("authToken");
            if(tokenBrut){
                const decodedToken = jwtDecode(tokenBrut);
                if(decodedToken){
                    decodedToken.roles[0] === "ROLE_ADMIN" ? setRoleAdmin(true) : setRoleAdmin(false);         
                }
            }
        }
        getDecodedToken();
    }, []);

    const [state, setState] = useState({
        columns: [
            {
                title: 'Email', field: 'email',

                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
            },
            { title: 'Rôle', field: 'roles[0]',
                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
                render: rowData => {
                    switch(rowData.roles[0]){
                        case 'ROLE_USER':
                            return 'UTILISATEUR';
                            break;
                        case 'USER':
                            return 'UTILISATEUR';
                            break;
                        case 'ADMIN':
                            return 'ADMINISTRATEUR';                               
                            break;
                    }
                },
                editComponent: (props) => (
                    <>
                        <FormControl required >
                            <Select
                                labelId="select-roleUtilisateur-required-label"
                                id="select-roleUtilisateur-required"
                                value={props.value ? props.value : ""}
                                defaultValue={props.value}
                                autoWidth
                                onChange={(e) => {
                                    props.onChange(e.target.value);
                                }}
                            >
                                <MenuItem value="USER" name="utilisateur">UTILISATEUR</MenuItem>
                                <MenuItem value="ADMIN" name="admin">ADMINISTRATEUR</MenuItem>
                            </Select>   
                            <FormHelperText>Requis</FormHelperText>
                        </FormControl>
                    </>
                )
            },
            { title: 'Prénom', field: 'prenom',
                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
            },
            { title: 'Nom', field: 'nom',
                headerStyle: {
                    textTransform: "uppercase",
                    textAlign: 'left',
                },
            },
            { title: 'Statut de validation', field: 'resetToken', editable: 'never', 
            /* cellStyle: rowData => rowData ? {backgroundColor:'orange'} : {backgroundColor:'green'}, */
                headerStyle:{
                    textAlign: 'center',
                    textTransform: "uppercase",
                },
                cellStyle:{
                    textAlign: 'center'
                },
                render: rowData => {
                    let val;
                   
                    if (rowData === undefined ){
                        val = <BsFillInfoCircleFill size={20} style={{fill: 'orange'}}/>                        
                    } else {
                        if (rowData.resetToken){
                            val = <BsFillInfoCircleFill size={20} style={{fill: 'orange'}}/>
                        }else{
                            val = <FcOk size={20} style={{fill: 'green'}}/>
                        }
                    }
                    return val;
                }
            },
        ],
    });

    let role = "";

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const tokenBrut = window.localStorage.getItem("authToken");
                if(tokenBrut){
                    const decodedToken = jwtDecode(tokenBrut);
                    
                    if(decodedToken){
                        role = decodedToken.roles[0];
                    }
                }
                const responseUsers = await UserAPI.findAllUsers();
                setUsers(responseUsers);
            } catch(error){
                console.log(error);
            }
        }

        fetchUsers();
    }, [refreshTab]);

    const handleRowClick = (event, rowData) => {
        event.preventDefault();
    }

    const addNewRow = async (param) => {
        if(param !== null){
            try {
                param.password = passwordGenerator.getPassword();
                const responseUser = await UserAPI.postUser(param);
                let formdata = new FormData();
                formdata.append('id', responseUser.id);

                await axios.post(SENDEMAIL, formdata);
                setRefreshTab(!refreshTab);
            } catch (error) {
                console.log(error);
                ErrorMessage.ErrorMessage();

            }
        } 
    }

    const updateRow = async (param) => {
        try {
            await UserAPI.changeOneUser(param.id, param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteRow = async (param) => {
        try {
            await UserAPI.deleteUser(param);
            setRefreshTab(!refreshTab);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>  
            <div className="corners">
                <div className="top left"></div>
                <div className="top right"></div>
                <div className="bottom right"></div>
                <div className="bottom left"></div>
                <Typography variant="h4" align='center' className={classes.titleText}>Administration</Typography>
            </div>      
            <div className={classes.page}>      
            <MaterialTable
                title={"Création des utilisateurs".toUpperCase()}
                columns={state.columns}
                data={users}
                onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                components={{
                    Toolbar: props => (
                    <div style={{ color:styling.colorTextHeader }}>
                        <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                    </div>
                    ),
                }}
                options={{
                    rowStyle: {
                        backgroundColor: styling.tableRowBackgroundColor,
                    },
                    headerStyle: {
                        backgroundColor: styling.tableHeaderBackgroundColor,
                        color: styling.tableHeaderColor
                    },
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    tableLayout: "fixed", //columns dimensions
                    //actionsColumnIndex: -1, //action column o right side
                    addRowPosition: 'first' //add row on top
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: "{from} à {to} sur {count}",
                        labelRowsSelect: "résultats par page",
                        labelRowsPerPage: "résultats par page",
                        firstAriaLabel: "Première page",
                        firstTooltip: "Première page",
                        previousAriaLabel: "Page précédente",
                        previousTooltip: "Page précédente",
                        nextAriaLabel: "Page suivante",
                        nextTooltip: "Page suivante",
                        lastAriaLabel: "Dernière page",
                        lastTooltip: "Dernière page"
                    },
                    toolbar: {
                        addRemoveColumns: "Ajouter ou supprimer des colonnes",
                        nRowsSelected: "{0} ligne(s) sélectionnée(s)",
                        showColumnsTitle: "Afficher les colonnes",
                        showColumnsAriaLabel: "Afficher les colonnes",
                        searchTooltip: "Recherche",
                        searchPlaceholder: "Recherche"
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage:
                            "Désolé, aucun enregistrement correspondant n'a été trouvé",
                        addTooltip: "Ajouter une ligne",
                        deleteTooltip: "Supprimer l'utilisateur",
                        editTooltip: "Modifier l'utilisateur",
                        filterRow: {
                            filterTooltip: "Filtrer"
                        },
                        editRow: {
                            deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                            cancelTooltip: "Annuler",
                            saveTooltip: "Sauvegarder"
                        }
                    },
                }}
                style = {{
                    color: styling.tableTextColor,
                }}
                icons={{
                    Add: props => <AddCircleRounded {...props} style={{fill: styling.tabButtonAddRow}} />,
                    Edit: props => <Edit  {...props} style={{fill: "#175Be5"}} />,
                    Delete: props => <DeleteForeverIcon   {...props} style={{fill: "#d50000"}} />,
                    Check: props => <Check   {...props} style={{fill: "#2e7d32"}} />,
                    Clear: props => <Clear    {...props} style={{fill: "#d50000"}} />,
                    ThirdStateCheck: props => <RemoveIcon  {...props} style={{fill: "#d50000"}} />,
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                        setTimeout(() => {
                            //console.log(newData);
                            addNewRow(newData);
                            resolve();
                        }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...users]; // data déja présente dans le tableau avant édition
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData; // remplacement des nouvelles données 
                            //setEntries([...dataUpdate]);
                            updateRow(newData);
                            resolve();
                        }, 600);
                    }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                const dataDelete = [...users];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                //setEntries([...dataDelete]);
                                deleteRow(oldData.id);
                                resolve();
                            }, 600);
                    }),
                }}
            />
            </div> 
        </>
    )
}

export default Administration;