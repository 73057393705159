import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import ValeursindicesAPI from "../services/ValeursindicesAPI"

import {
    TextField,
    FormHelperText,
    Box,
    Grid
} from '@material-ui/core';

//icons

import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";

import * as styling from '../services/styling';

const useStyles = makeStyles(theme => ({
    table: {
        padding: '30px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar: {
    }
}));

const ValeurTab = ({ ShowSecondTable }) => {
    const classes = useStyles();

    const [chargement, setChargement] = useState({
        isLoading : false,
    });

    const [columnDefinition, setColumnDefinition] = useState({
        columns: [
            {
                title: "Date de valeur", field: "datevaleur", type: "date",
                headerStyle: {
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-valeur"
                                    value={props.value ? props.value : new Date()}
                                    onChange={(e) => {
                                        props.onChange(e);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Date de valeur',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </>
                )
            },
            {
                title: "Valeur", field: 'valeur', type: "numeric",
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
            {
                title: "Date de publication MTBP", field: "datepublicationmtbp", type: "date",
                headerStyle: {
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-pulication"
                                    value={props.value ? props.value : new Date()}
                                    onChange={(e) => {
                                        props.onChange(e);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Date de publication MTBP',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </>
                )
            },
            {
                title: "Observation", field: 'observation', cellStyle: {
                    width: "25%",
                    maxWidth: "25%"
                },
                headerStyle: {
                    width: "25%",
                    maxWidth: "25%",
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        style={{ minWidth: "100%" }}
                        rows={4}
                        defaultValue={props.value}
                        onChange={(e) => {
                            props.onChange(e.target.value);
                        }}
                    />
                ),
            },
            {
                title: "Obs Statut", field: 'obsStatut', cellStyle: {
                    width: "25%",
                    maxWidth: "25%"
                },
                headerStyle: {
                    width: "25%",
                    maxWidth: "25%",
                    textTransform: "uppercase",
                },
                editComponent: (props) => (
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        style={{ minWidth: "100%" }}
                        rows={4}
                        defaultValue={props.value}
                        //variant="outlined"
                        onChange={(e) => {
                            props.onChange(e.target.value);
                        }}
                    />
                ),
            },
        ],
    });

    const [valeursindices, setValeursindices] = useState([]);

    const fetchValeursindices = async (param) => {
        try {
            setChargement({isLoading : true})   
            const responseValeurs = await ValeursindicesAPI.findValeursByIndice(param);
            setValeursindices(responseValeurs);
            setChargement({isLoading : false})   
        } catch (error) {
            setChargement({isLoading : false})   
            console.log(error);
        }
    }

    useEffect(() => {
        if (ShowSecondTable.idindice !== undefined) {
            fetchValeursindices(ShowSecondTable.idindice);
        }
    }, [ShowSecondTable]);



    const addNewRow = async (param) => {
        try {
            await ValeursindicesAPI.createRowValue(param);
        } catch (error) {
            console.log(error);
        }
    }

    const updateRow = async (param) => {
        try {
            await ValeursindicesAPI.updateRowValeur(param.idvaleurindice, param);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteRow = async (param) => {
        try {
            await ValeursindicesAPI.deleteRowValeur(param);
        } catch (error) {
            console.log(error)
        }
    }

    const destructuringTableData = (obj) => {
        const {tableData, ...dataWithoutTableData} = obj;
        return dataWithoutTableData
    };
    const objectCompare = (obj1, obj2) => {
        const Obj1_keys = Object.keys(obj1);
        const Obj2_keys = Object.keys(obj2);
        if (Obj1_keys.length !== Obj2_keys.length){
            return false;
        }
        for (let k of Obj1_keys){
            if(obj1[k] !== obj2[k]){
               return false;
            }
        }
        return true;
    }
    const rowChange = (old, newEntrie) => {
        return objectCompare(destructuringTableData(old),destructuringTableData(newEntrie));
    }

    return (
        <>
            <div className={classes.table}>
                <MaterialTable
                    title={'MODIFIER UNE VALEUR'}
                    columns={columnDefinition.columns}
                    data={valeursindices}
                    isLoading={chargement.isLoading}
                    components={{
                        Toolbar: props => (
                            <div style={{ color: styling.colorTextHeader }}>
                                <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                            </div>
                        ),
                    }}
                    options={{
                        rowStyle: {
                            backgroundColor: styling.tableRowBackgroundColor,
                        },
                        headerStyle: {
                            backgroundColor: styling.tableHeaderBackgroundColor,
                            color: styling.tableHeaderColor
                        },
                        pageSize: 12,
                        pageSizeOptions: [12, 24, 48],
                        //actionsColumnIndex: -1, //action column o right side
                        addRowPosition: 'first' //add row on top
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage:
                                "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            addTooltip: "Ajouter une ligne",
                            deleteTooltip: "Supprimer",
                            editTooltip: "Modifier",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                deleteText: "Êtes-vous sûr de vouloir supprimer cet élément ? Vous allez perdre TOUTES les données associées",
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                    icons={{
                        Add: props => <AddCircleRounded {...props} style={{ fill: styling.tabButtonAddRow }} />,
                        Edit: props => <Edit  {...props} style={{ fill: "#ff9800" }} />,
                        Delete: props => <DeleteForeverIcon   {...props} style={{ fill: "#d50000" }} />,
                        Check: props => <Check   {...props} style={{ fill: "#2e7d32" }} />,
                        Clear: props => <Clear    {...props} style={{ fill: "#d50000" }} />,
                    }}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                newData.submitted = true;
                                if (newData.datevaleur === undefined) {
                                    newData.datevaleur = new Date();
                                }
                                if (newData.datepublicationmtbp === undefined) {
                                    newData.datepublicationmtbp = new Date();
                                }
                                newData.idindice = ShowSecondTable.idindice;

                                addNewRow(newData);
                                resolve();
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {

                                    if(!rowChange(oldData,newData)){
                                        const dataUpdate = [...valeursindices];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        
                                        setValeursindices([...dataUpdate]);
                                        updateRow(newData);
                                        newData.submitted = true;                                        
                                    }
                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                const dataDelete = [...valeursindices];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setValeursindices([...dataDelete]);
                                deleteRow(oldData.idvaleurindice);
                                resolve();
                            }),
                    }}
                />
            </div>
        </>
    )
}

export default ValeurTab