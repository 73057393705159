import axios from "axios";
import {LISTEINDICES_API} from "../config.js";

function findAllIndices(){
    return axios
        .get(LISTEINDICES_API + "?majEspelia=1")
        .then(response => response.data['hydra:member']);
}

function updateRowIndice(param, param2){
    return axios
        .put(LISTEINDICES_API + "/" + param, param2)
        .then(response => response.data)
}

export default {
    findAllIndices,
    updateRowIndice
}