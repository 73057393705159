import axios from "axios";
import {VALEURSINDICES_API} from "../config.js";

function findValeursByIndice(param){
    return axios
        .get(VALEURSINDICES_API + "?idindice=" + param +"&order[datevaleur]=desc")
        .then(response => response.data['hydra:member']);
}

function createRowValue(param){
    return axios
    .post(VALEURSINDICES_API, param)
    .then(response => response.data)

}

function updateRowValeur(param, param2){
    return axios
        .put(VALEURSINDICES_API + "/" + param, param2)
        .then(response => response.data)
}

function deleteRowValeur(param){
    return axios
    .delete(VALEURSINDICES_API + "/" + param)
    .then(response => response.data)

}


export default {
    findValeursByIndice,
    createRowValue,
    updateRowValeur,
    deleteRowValeur
}