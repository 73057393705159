//navbar - toolbar
export const navbarBackgroundColor = "#1a6698";
export const toolbarBackgroundColor = "#1a6698";
//title logo  
export const colorTextTitle = '#1a6698';
/* corners css are in app.css */

//table toolbar
export const colorTextHeader = '#69aec4';
export const backGroundHeader = "#FFF";

//table headerStyle
export const tableHeaderBackgroundColor = '#1a6698';
export const tableHeaderColor = 'white';

//table row style
export const tableRowBackgroundColor = 'white';

//tab button add
export const tabButtonAddRow = "#69aec4" ;

//table text color

export const tableTextColor = '#69aec4';