import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, {MTableToolbar} from 'material-table';

import ListeindicesAPI from "../services/ListeindicesAPI"
import ValeurTab from "./valeurTab"

import { Typography, 
         InputLabel,
         MenuItem,
         FormControl,
         FormHelperText,
         Select,
         Divider, 
         Grid,
         Box,
         TextField,
         Tooltip  
        } from '@material-ui/core';

import { FcList } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import * as styling from '../services/styling';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: theme.palette.grey[300],
    },
    header : {
        paddingTop: 50,
    },
    table: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    titleText : {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop:'25px',
        color : styling.colorTextTitle,
        textTransform: "uppercase"
    },
}));

const IndiceTab = () => {
    const classes = useStyles();

    const [chargement, setChargement] = useState({
        isLoading : false,
    });

    let tableRef = useRef();
    const [allListeindices, setAllListeindices] = useState([]);

    useEffect(() => {
        const fetchAllListeindices = async () => {
            try {
                setChargement({isLoading : true})                   
                const responseListeindices = await ListeindicesAPI.findAllIndices();
                setAllListeindices(responseListeindices);
                setChargement({isLoading : false})        
            } catch(error) {
                console.log(error);
                setChargement({isLoading : false})      
            }
        }    
        fetchAllListeindices();
    }, []);

    const [state, setState] = useState({
        columns: [
            {
                title: "Sigle", field: 'sigle', editable: "never",
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
            {
                title: "Description", field: "description", cellStyle: {
                    width: "45%",
                    maxWidth: "45%"
                },
                headerStyle: {
                    width: "45%",
                    maxWidth: "45%",
                    textTransform: "uppercase",
                },
                editComponent:(props) => (
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        style={{minWidth: "100%"}}
                        rows={4}
                        defaultValue={props.value}
                        //variant="outlined"
                        onChange={(e)=>{
                            props.onChange(e.target.value);
                        }}
                  />
                ),
            },
            {
                title: "Formule de raccordement", field: "formuleRaccordement", editable: "never",
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
            {
                title: "Indice actuel", field: "indiceActuel",
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
            {
                title: "Valeur raccordement formule", field: 'valeurRaccordementFormule', type: "numeric",               
                headerStyle: {
                    textTransform: "uppercase",
                },
            },
        ],
    });

    const updateRow = async (param) => {
        try {
            await ListeindicesAPI.updateRowIndice(param.idindice, param);
        } catch (error) {
            console.log(error);
        }
    }

    const [refreshTab, setRefreshTab] = useState(false);


    const [displaySecondTable,setDisplaySecondTable] = useState("hidden");
    const [titleSecondTable, setTitleSecondTable] = useState("");
    const [rappelSigle, setRappelSigle] = useState("");
    const [sendSelectedRowTable, setSendSelectedRowTable] = useState([]);

    //scroll
    const scroll = () => {
        const section = document.querySelector('#anchorSousTab');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleRowClick = (event, rowData) => {
        event.preventDefault();
        setTitleSecondTable(rowData.description);
        setRappelSigle(rowData.sigle);
        setSendSelectedRowTable(rowData);

        if (displaySecondTable === "hidden") {
            setDisplaySecondTable('visible');
            scroll();
        } else if (displaySecondTable === "visible") {
            scroll();
        } else {
            setDisplaySecondTable('hidden');
        }
    }


    return (
        <>
            {/* <Typography variant="h4" align='center' className={classes.titleText}>Mise à jour des indices gs'o</Typography> */}
            <div className="corners">
                <div className="top left"></div>
                <div className="top right"></div>
                <div className="bottom right"></div>
                <div className="bottom left"></div>
                <Typography variant="h4" align='center' className={classes.titleText}>Mise à jour des indices gs'o</Typography>
            </div>
            <div className={classes.table}>
                <MaterialTable
                    title={'SELECTION DE L\'INDICE'}
                    columns={state.columns}
                    data={allListeindices}
                    isLoading={chargement.isLoading}
                    onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    components={{
                        Toolbar: props => (
                            <div style={{ color: styling.colorTextHeader }}>
                                <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
                            </div>
                        ),
                    }}
                    options={{
                        rowStyle: {
                            backgroundColor: styling.tableRowBackgroundColor,
                        },
                        headerStyle: {
                            backgroundColor: styling.tableHeaderBackgroundColor,
                            color: styling.tableHeaderColor
                        },
                        pageSize: 12,
                        pageSizeOptions: [12, 24, 48],
                        //actionsColumnIndex: -1, //action column o right side
                        addRowPosition: 'first' //add row on top
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: "{from} à {to} sur {count}",
                            labelRowsSelect: "résultats par page",
                            labelRowsPerPage: "résultats par page",
                            firstAriaLabel: "Première page",
                            firstTooltip: "Première page",
                            previousAriaLabel: "Page précédente",
                            previousTooltip: "Page précédente",
                            nextAriaLabel: "Page suivante",
                            nextTooltip: "Page suivante",
                            lastAriaLabel: "Dernière page",
                            lastTooltip: "Dernière page"
                        },
                        toolbar: {
                            searchTooltip: "Recherche",
                            searchPlaceholder: "Recherche"
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: "Désolé, aucun enregistrement correspondant n'a été trouvé",
                            editTooltip: "Modifier",
                            filterRow: {
                                filterTooltip: "Filtrer"
                            },
                            editRow: {
                                cancelTooltip: "Annuler",
                                saveTooltip: "Sauvegarder"
                            }
                        },
                    }}
                    icons={{
                        Edit: props => <Edit   {...props} style={{ fill: "#ff9800" }} />,
                        Check: props => <Check {...props} style={{ fill: "#2e7d32" }} />,
                        Clear: props => <Clear {...props} style={{ fill: "#d50000" }} />,
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    newData.submitted = true;
                                    // if (!newData.date) {
                                    //     setNameError({
                                    //         error: true,
                                    //         label: "champs requis",
                                    //         helperText: "erreur",
                                    //         validateInput: true,
                                    //     });
                                    //     reject();
                                    //     return;
                                    // }
                                    const dataUpdate = [...allListeindices];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setAllListeindices([...dataUpdate]);
                                    updateRow(newData);
                                    resolve();
                                }, 1000);
                            }),
                    }}
                />
            </div>
            <div id="anchorSousTab">{'\u00A0'}</div>
            <div style={{ visibility: displaySecondTable, backgroundColor: "#1a6698", marginLeft: 30, marginRight: 30, color: "white" }} >
                <Typography
                    className={classes.title}
                    variant="h5"
                    component="h3"
                    align="left"
                >
                    {titleSecondTable}
                </Typography>
                <Typography
                    className={classes.title}
                    variant="h5"
                    component="h3"
                    align="center"
                >
                    <span style={{fontStyle: "italic"}}>Sigle</span> : {rappelSigle}
                </Typography>
            </div>        
            <div  style={{visibility: displaySecondTable}} >
                <ValeurTab ShowSecondTable={sendSelectedRowTable} />
            </div>
        </>
    )
}

export default IndiceTab