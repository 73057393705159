import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//image
import imgAccueil from '../images/water3.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },

    image: {
        backgroundImage: "url(" + imgAccueil + ")",
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

const Accueil = () => {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={12} md={12} className={classes.image} />
        </Grid>
    )
}

export default Accueil;