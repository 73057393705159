import axios from "axios";
import jwtDecode from "jwt-decode";
import {LOGINCHECK_API} from "../config.js";

function authenticate(credentials) {
    return axios
        .post(LOGINCHECK_API, credentials)
        .then(response => response.data.token)
        .then(token => {
                window.localStorage.setItem("authToken", token);
                setAxiosToken(token);
        })
}

function logout() {
    window.localStorage.removeItem("authToken");
    delete axios.defaults.headers["Authorization"];
}

function setAxiosToken(token) {
    axios.defaults.headers["Authorization"] = "Bearer " + token;
}

function setup() {
    const token = window.localStorage.getItem("authToken");

    if(token) {
        const jwtData = jwtDecode(token);
        if(jwtData.exp * 1000 > new Date().getTime()){
            setAxiosToken(token);
        }
    }
}

function isAuthenticated() {
    const token = window.localStorage.getItem("authToken");

    if(token) {
        const jwtData = jwtDecode(token);
        if(jwtData.exp * 1000 > new Date().getTime()){
            return true;
        }
        return false;
    }
    return false;
}

function getDecodedToken() {
    const tokenBrut = window.localStorage.getItem("authToken");

    if(tokenBrut){
      const decodedToken = jwtDecode(tokenBrut);
      const accessRole = decodedToken.roles;

        for(let i = 0; i < accessRole.length; i++){
            if(accessRole[i] === "ADMIN"){
                return true;
            } else {
                return false;
            }
        }
    }
  }

export default {
    authenticate,
    logout,
    setup,
    isAuthenticated,
    getDecodedToken
}